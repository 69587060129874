import newsCategories from '../../data/news-categories.json';

export class NewsCategory {
	id: string = '';
	name: string = '';
	code: string = '';
	color: string = '';
}

export const getNewsCategories = (): NewsCategory[] => newsCategories;

export const getNewCategoryByCode = (code: string): NewsCategory | undefined =>
	newsCategories.find((category) => category.code === code);

export const getNewCategoryById = (id: string): NewsCategory | undefined =>
	newsCategories.find((category) => category.id === id);
