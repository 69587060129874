import Image from 'next/image';
import React from 'react';
import underConstructionImage from '../../../public/images/under-construction.png';
import { getNewCategoryById, News } from '../../models/dtos';

type Props = {
	aNew: News;
};

export const NewsCardComponent = ({ aNew }: Props) => {
	const category = getNewCategoryById(aNew.category)!;

	return (
		<div className="p-4 md:w-1/3">
			<div className="h-full rounded-xl shadow-cla-blue bg-white shadow-lg overflow-hidden">
				<Image
					src={aNew.image || underConstructionImage}
					className="lg:h-48 md:h-36 w-full object-cover object-center scale-110 transition-all duration-400 hover:scale-100"
					alt={`Ayuda Finanzas - ${aNew.name}`}
					title={`Ayuda Finanzas - ${aNew.name}`}
					width={400}
					height={250}
				/>
				<div className="p-6">
					<h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">
						{category.name}
					</h2>
					<h1 className="title-font text-lg font-medium text-gray-600 mb-3">{aNew.name}</h1>
					<p className="leading-relaxed mb-3">{aNew.shortDescription}</p>
					<div className="flex items-center flex-wrap ">
						<a
							href={`/noticias/${category.code}/${aNew.code}`}
							className="bg-yellow-400 hover:scale-105 drop-shadow-md shadow-cla-blue px-4 py-1 rounded-lg">
							Leer más
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewsCardComponent;
