import news from '../../data/news.json';

export class News {
	id: string = '';
	createdAt: string = '';
	updatedAt: string = '';
	name: string = '';
	code: string = '';
	shortDescription: string = '';
	description?: string = '';
	category: string = '';
	tags: string[] = [];
	image?: string;
	template: string = '';
	active: boolean = false;
}

export const getNews = (limit: number | undefined = undefined): News[] => {
	return news.slice(0, limit);
};
