import type { NextPage } from 'next';
import Link from 'next/link';
import _ from 'lodash';
import Image from 'next/image';
import { getNews, News } from '../models/dtos';
import NewsGridComponent from '../components/news/news-grid';
//import { NewsGridComponent } from 'ui';

type Props = {
	news: News[];
};

const Home: NextPage<Props> = ({ news }: Props) => {
	return (
		<div className="flex flex-col">
			<section className="flex pt-10">
				<div className=" flex-col">
					<h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
						Últimas noticias
					</h2>
					<NewsGridComponent news={news} />
				</div>
			</section>
			{/* <section className="pt-10">
				<div className=" flex-col">
					<h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
						Descubre alguna nueva marca
					</h2>
					<div className="flex flex-row">{brands}</div>
				</div>
			</section>
			<section className="pt-10">
				<div className=" flex-col">
					<h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
						Descubre algún nuevo modelo
					</h2>
					<div>
						<ModelsGridComponent models={randomModels} />
					</div>
				</div>
			</section> */}
		</div>
	);
};

export async function getStaticProps() {
	return {
		props: {
			news: getNews(3),
			title: 'Marcas de coches'
		}
	};
}

export default Home;
